/* glow radius */
/* glow offset */
/* duration */
/* # of letters */
.homeText {
  animation: glow 2s ease-in-out both infinite;
}
.homeText:nth-child(1) {
  animation-delay: -0.2857142857s;
}
.homeText:nth-child(2) {
  animation-delay: -0.5714285714s;
}
.homeText:nth-child(3) {
  animation-delay: -0.8571428571s;
}
.homeText:nth-child(4) {
  animation-delay: -1.1428571429s;
}
.homeText:nth-child(5) {
  animation-delay: -1.4285714286s;
}
.homeText:nth-child(6) {
  animation-delay: -1.7142857143s;
}
.homeText:nth-child(7) {
  animation-delay: -2s;
}
@keyframes glow {
  0%, 100% {
    text-shadow: 0 0 5.3333333333px snow, 0 0px 20px tomato, 0px 0px 50px yellow, 0px 0px 50px tomato;
 }
  33% {
    text-shadow: 0 0 5.3333333333px snow, 0 0px 20px cyan, 0px 0px 50px tomato, 0px 0px 50px cyan;
 }
  66% {
    text-shadow: 0 0 5.3333333333px snow, 0 0px 20px yellow, 0px 0px 50px cyan, 0px 0px 50px yellow;
 }
}




/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  animation: l14 4s infinite;
}
.loader::before,
.loader::after {    
  content: "";
  grid-area: 1/1;
  border: 8px solid;
  border-radius: 50%;
  border-color: red red #0000 #0000;
  mix-blend-mode: darken;
  animation: l14 1s infinite linear;
}
.loader::after {
  border-color: #0000 #0000 blue blue;
  animation-direction: reverse;
}
@keyframes l14{ 
  100%{transform: rotate(1turn)}
}


@font-face {
  font-family: 'Blessed Day';
  src: url('./assests/AMANDASIGNATURE-9G4K.OTF') format('opentype');
}
@font-face {
  font-family: 'barcelona';
  src: url('./assests/BARCELONAREGULAR-VMVMD.OTF') format('opentype');
}
@font-face {
  font-family: 'Amsterdam';
  src: url('./assests/AMSTERDAM-ZVGQM.TTF') format('truetype');
}
@font-face {
  font-family: 'Nobella PERSONAL USE ONLY';
  src: url('./assests/NOBELLAPERSONALUSEONLYPERSONALUSEONLY-8MLJZ.OTF') format('opentype');
}
@font-face {
  font-family: 'Amanda Sunshine';
  src: url('./assests/AMANDASUNSHINE-V4ZOZ.OTF') format('opentype');
}
@font-face {
  font-family: 'Amanda Signature';
  src: url('./assests/AMANDASIGNATURE-9G4K.OTF') format('opentype');
}
@font-face {
  font-family: 'The Austin';
  src: url('./assests/THEAUSTIN-JEVX9.TTF') format('truetype');
}
@font-face {
  font-family: 'Astagina Signature';
  src: url('./assests/ASTAGINASIGNATURE-NRDAV.TTF') format('truetype');
}
@font-face {
  font-family: 'Rocket Clouds DEMO';
  src: url('./assests/ROCKETCLOUDSDEMOREGULAR-MLAYJ.TTF') format('truetype');
}
@font-face {
  font-family: 'Royalty';
  src: url('./assests/ROYALTY-EZGQL.OTF') format('opentype');
}
@font-face {
  font-family: 'Signature Forest';
  src: url('./assests/SIGNATUREFOREST-1GJ2G.TTF') format('truetype');
}
@font-face {
  font-family: 'Neoneon';
  src: url('./assests/NEONEON-3ZAD6.OTF') format('opentype');
}

#svgAnime {
  /* filter: drop-shadow(rgb(255, 255, 255) 0px 0px 5px,
    rgb(255, 255, 255) 0px 0px 10px,
    rgb(255, 253, 207) 0px 0px 20px, 
    rgb(255, 253, 207) 0px 0px 30px, 
    rgb(255, 253, 207) 0px 0px 40px, 
    rgb(255, 253, 207) 0px 0px 55px,
    rgb(255, 253, 207) 0px 0px 75px); */
  text-shadow: rgb(255, 255, 255) 0px 0px 5px,
  rgb(255, 255, 255) 0px 0px 10px,
  rgb(255, 253, 207) 0px 0px 20px, 
  rgb(255, 253, 207) 0px 0px 30px, 
  rgb(255, 253, 207) 0px 0px 40px, 
  rgb(255, 253, 207) 0px 0px 55px,
  rgb(255, 253, 207) 0px 0px 75px;
  /* box-shadow: rgb(255, 255, 255) 0px 0px 5px,
  rgb(255, 255, 255) 0px 0px 10px,
  rgb(255, 253, 207) 0px 0px 20px, 
  rgb(255, 253, 207) 0px 0px 30px, 
  rgb(255, 253, 207) 0px 0px 40px, 
  rgb(255, 253, 207) 0px 0px 55px,
  rgb(255, 253, 207) 0px 0px 75px; */
  -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));

}

.mySwiper{
  --swiper-navigation-size: 44px;
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 0px;
  --swiper-navigation-color: #CFFF04;
  --swiper-navigation-background: #fff;
}


/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #afb2bf;
}
/* Chrome, Edge and Safari */
::-webkit-scrollbar {
  background: #1c1d1e;
}

*::-webkit-scrollbar-track {
  background: #1c1d1e;
}

*::-webkit-scrollbar-thumb {
  background-color: #0e0d0d;
  /* border-radius: 10px; */
}

*:active::-webkit-scrollbar-thumb,
*:focus::-webkit-scrollbar-thumb,
*:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

